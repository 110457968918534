<template>
    <el-col :span="8">
        <div class="praise-card">
            <div class="header-wrap">
                <img class="avator" :src="data.avator || ''" alt="">
                <div class="name-wrap">
                    <div class="username">{{ data.userName }}</div>
                    <el-rate class="rate-wrap"
                        v-model="data.rating"
                        disabled
                        :colors="['#99A9BF', '#FF9900', '#FF9900']">
                    </el-rate>
                </div>
            </div>
            <div class="text-wrap">
                {{ data.content }}
            </div>
        </div>
    </el-col>
</template>

<script>
export default {
    props: ['data']
}
</script>

<style lang="scss" scoped>
.praise-card{
    margin: 20px 0;
    min-width: 362px;
    min-height: 358px;
    background-color: white;
    border: 1px solid #eeeeee;
    display: flex;
    flex-direction: column;
    transition: .3s ease-in;
    transform: perspective(500px) scale(0.95);
    &:hover{
        transform: perspective(500px) scale(1);
        box-shadow: 0 2px 12px 0 rgba(0,0,0,.1);
        transition: all .8s cubic-bezier(0.165, 0.84, 0.44, 1);
    }
    .header-wrap{
        display: flex;
        flex-direction: row;
        margin: 24px;
        .avator{
            display: inline-block;
            width: 45px;
            height: 45px;
            margin-right: 14px;
        }
        .name-wrap{
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            .username{
                height: 28px;
                font-size: 20px;
                font-family: PingFangSC, PingFangSC-Medium;
                font-weight: 500;
                text-align: left;
                color: #000000;
                line-height: 28px;
            }
            .rate-wrap /deep/ .el-rate__item .el-rate__icon{
                width: 14px;
                margin: 1px !important;
            }
        }
    }
    .text-wrap{
        margin: 0 24px;
        font-size: 14px;
        font-family: PingFangSC, PingFangSC-Regular;
        font-weight: 400;
        color: #333333;
        line-height: 20px;
    }
}
</style>
