<template>
    <div class="first-wrap" :style="`background: url(${bg}) center no-repeat;background-size: 100% 100%;background-color: white;`">
        <div class="anchor" id="firstAnchorId"></div>
        <div class="main-content-wrap content-wrap">
            <div class="left">
                <div class="title">{{ $t('rap.home.label.first-title') }}</div>
                <div class="text">{{ $t('rap.home.label.first-text') }}</div>
                <div class="btn-img"
                    :style="`background: url(${btnBg}) center no-repeat;background-size: 100% 100%;`"
                    @click="$router.push({ path: '/login' })">
                    {{ 'Sign in now' }}
                </div>
            </div>
            <img class="img" src="@/assets/home/home-img-01.png" alt="">
        </div>
    </div>
</template>

<script>
export default {
    data () {
        return {
            bg: require('@/assets/home/home-bg-01.png'),
            btnBg: require('@/assets/home/home-banner-btn.png')
        }
    }
}
</script>

<style lang="scss" scoped>
    .first-wrap{
        display: flex;
        justify-content: center;
        height: 527px;
        background-color: white;
        overflow: hidden;
        position: relative;
        .anchor{
            position: absolute;
            top: -$headerHeight;
            z-index: -1;
        }
        .content-wrap{
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            padding-top: 89px;
            .title{
                width: 620px;
                height: 150px;
                font-size: 36px;
                font-family: PingFangSC, PingFangSC-Medium;
                font-weight: 500;
                text-align: left;
                color: #040404;
                line-height: 50px;
            }
            .text{
                margin-top: 24px;
                width: 551px;
                height: 88px;
                opacity: 0.8;
                font-size: 24px;
                font-weight: 400;
                text-align: left;
                color: #040404;
                line-height: 25px;
            }
            .btn-img{
                width: 200px;
                height: 70px;
                display: flex;
                justify-content: center;
                align-items: center;
                color: white;
                font-size: 14px;
                font-family: PingFangSC, PingFangSC-Medium;
                font-weight: 500;
                margin-left: -15px;
                cursor: pointer;
                transition: all .3s;
                &:hover{
                    opacity: 0.75;
                    transition: all .5s;
                }
            }
            .img{
                display: inline-block;
                width: 481px;
                height: 305px;
            }
        }
    }
</style>
