<template>
    <div class="high-praise">
        <div class="main-content-wrap">
            <div class="title-wrap">
                <div class="title">{{ $t('rap.home.label.praise-title') }}</div>
                <div class="text">{{ $t('rap.home.label.praise-text') }}</div>
            </div>
            <div class="carousel-wrap"
                @mouseover="autoplay=false"
                @mouseout="autoplay=true">
                <img class="arrow-btn left"
                    @mouseover="lArrowHover=true"
                    @mouseleave="lArrowHover=false"
                    :src=" lArrowHover ? require('@/assets/home/arrow-left-hover.svg') : require('@/assets/home/arrow-left.png')"
                    @click="nextCarousel"/>
                <img class="arrow-btn right"
                    @mouseover="rArrowHover=true"
                    @mouseleave="rArrowHover=false"
                    :src=" rArrowHover ? require('@/assets/home/arrow-right-hover.svg') : require('@/assets/home/arrow-right.png')"
                    @click="preCarousel"/>
                <el-carousel ref="carousel"
                    :interval="5000"
                    arrow="never"
                    height="390px"
                    :autoplay="autoplay"
                    indicator-position="none">
                    <el-carousel-item v-for="(item, index) in dataList" :key="index">
                        <el-row :gutter="30">
                            <praise-card v-for="(sItem, sIndex) in item" :key="sIndex" :data="sItem"></praise-card>
                        </el-row>
                    </el-carousel-item>
                </el-carousel>
            </div>
        </div>
    </div>
</template>

<script>
import PraiseCard from './praise-card.vue'
export default {
    components: {
        PraiseCard
    },
    data () {
        return {
            dataList: [
                [
                    {
                        userName: this.$t('rap.home.label.praise-username-01'),
                        avator: require('@/assets/home/home-praise-avator-01.png'),
                        rating: 5.0,
                        content: this.$t('rap.home.label.praise-desc-01')
                    },
                    {
                        userName: this.$t('rap.home.label.praise-username-02'),
                        avator: require('@/assets/home/home-praise-avator-02.png'),
                        rating: 4.5,
                        content: this.$t('rap.home.label.praise-desc-02')
                    },
                    {
                        userName: this.$t('rap.home.label.praise-username-03'),
                        avator: require('@/assets/home/home-praise-avator-03.png'),
                        rating: 5.0,
                        content: this.$t('rap.home.label.praise-desc-03')
                    }
                ],
                [
                    {
                        userName: this.$t('rap.home.label.praise-username-01'),
                        avator: require('@/assets/home/home-praise-avator-01.png'),
                        rating: 5.0,
                        content: this.$t('rap.home.label.praise-desc-01')
                    },
                    {
                        userName: this.$t('rap.home.label.praise-username-02'),
                        avator: require('@/assets/home/home-praise-avator-02.png'),
                        rating: 4.5,
                        content: this.$t('rap.home.label.praise-desc-02')
                    },
                    {
                        userName: this.$t('rap.home.label.praise-username-03'),
                        avator: require('@/assets/home/home-praise-avator-03.png'),
                        rating: 5.0,
                        content: this.$t('rap.home.label.praise-desc-03')
                    }
                ],
                [
                    {
                        userName: this.$t('rap.home.label.praise-username-01'),
                        avator: require('@/assets/home/home-praise-avator-01.png'),
                        rating: 5.0,
                        content: this.$t('rap.home.label.praise-desc-01')
                    },
                    {
                        userName: this.$t('rap.home.label.praise-username-02'),
                        avator: require('@/assets/home/home-praise-avator-02.png'),
                        rating: 4.5,
                        content: this.$t('rap.home.label.praise-desc-02')
                    },
                    {
                        userName: this.$t('rap.home.label.praise-username-03'),
                        avator: require('@/assets/home/home-praise-avator-03.png'),
                        rating: 5.0,
                        content: this.$t('rap.home.label.praise-desc-03')
                    }
                ]
            ],
            autoplay: true,
            lArrowHover: false,
            rArrowHover: false
        }
    },
    methods: {
        nextCarousel () {
            this.$refs.carousel && this.$refs.carousel.next()
        },
        preCarousel () {
            this.$refs.carousel && this.$refs.carousel.prev()
        }
    }
}
</script>

<style lang="scss" scoped>
.high-praise{
    background: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .title-wrap {
        margin-top: 66px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .title{
            height: 65px;
            font-size: 46px;
            font-family: PingFangSC, PingFangSC-Medium;
            font-weight: 500;
            text-align: left;
            color: #000000;
            line-height: 65px;
        }
        .text{
            height: 30px;
            opacity: 0.8;
            font-size: 20px;
            font-family: PingFangSC, PingFangSC-Regular;
            font-weight: 400;
            text-align: left;
            color: #333333;
            line-height: 30px;
        }
    }
    .carousel-wrap{
        margin: 18px 0 50px 0;
        background: white;
        position: relative;
        overflow: hidden;
        /deep/ .el-carousel--horizontal {
            overflow-y: hidden;
        }
        .el-row{
            padding: 0 70px;
        }
        .el-col{
            display: flex;
            justify-content: center;
            align-items: center;
        }
        .arrow-btn{
            display: inline-block;
            width: 28px;
            height: 28px;
            position: absolute;
            top: 0;
            bottom: 0;
            margin: auto;
            z-index: 10;
            opacity: 0;
            cursor: pointer;
            &.left{
                left: 0;
            }
            &.right{
                right: 0;
            }
            transition: all .5s;
        }
        &:hover .arrow-btn{
            opacity: 1;
            &.left{
                left: 20px;
            }
            &.right{
                right: 20px;
            }
            transition: all .7s;
        }
    }
}
</style>
