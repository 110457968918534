<template>
    <div class="our-advantage">
        <div class="main-content-wrap content-wrap">
            <div class="title-wrap">
                <div class="title">{{ $t('rap.home.label.advantage-title') }}</div>
                <div class="text">{{ $t('rap.home.label.advantage-text') }}</div>
            </div>
            <div class="list-wrap">
                <el-row :gutter="30" v-for="(item, index) in lists" :key="index">
                    <el-col class="card-col" :span="8" v-for="(card, sIndex) in item" :key="sIndex">
                        <div class="item-card">
                            <img class="img" :src="card.imgUrl" alt="">
                            <p class="title">{{ card.title }}</p>
                            <p class="text">{{ card.text }}</p>
                        </div>
                    </el-col>
                </el-row>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data () {
        return {
            lists: [
                [
                    {
                        title: this.$t('rap.home.label.advantage-inventory-title'),
                        text: this.$t('rap.home.label.advantage-inventory-text'),
                        imgUrl: require('@/assets/home/home-advantage-01.png')
                    },
                    {
                        title: this.$t('rap.home.label.advantage-fast-title'),
                        text: this.$t('rap.home.label.advantage-fast-text'),
                        imgUrl: require('@/assets/home/home-advantage-02.png')
                    },
                    {
                        title: this.$t('rap.home.label.advantage-smart-title'),
                        text: this.$t('rap.home.label.advantage-smart-text'),
                        imgUrl: require('@/assets/home/home-advantage-03.png')
                    }
                ],
                [
                    {
                        title: this.$t('rap.home.label.advantage-sales-title'),
                        text: this.$t('rap.home.label.advantage-sales-text'),
                        imgUrl: require('@/assets/home/home-advantage-04.png')
                    },
                    {
                        title: this.$t('rap.home.label.advantage-featured-title'),
                        text: this.$t('rap.home.label.advantage-featured-text'),
                        imgUrl: require('@/assets/home/home-advantage-05.png')
                    },
                    {
                        title: this.$t('rap.home.label.advantage-automatic-title'),
                        text: this.$t('rap.home.label.advantage-automatic-text'),
                        imgUrl: require('@/assets/home/home-advantage-06.png')
                    }
                ]
            ]
        }
    }
}
</script>

<style lang="scss" scoped>
    .our-advantage{
        background: white;
        display: flex;
        justify-content: center;
        align-items: center;
        padding-bottom: 10px;
        .title-wrap {
            margin-top: 78px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            .title{
                height: 65px;
                font-size: 46px;
                font-family: PingFangSC, PingFangSC-Medium;
                font-weight: 500;
                text-align: left;
                color: #000000;
                line-height: 65px;
            }
            .text{
                height: 30px;
                opacity: 0.8;
                font-size: 20px;
                font-family: PingFangSC, PingFangSC-Regular;
                font-weight: 400;
                text-align: left;
                color: #333333;
                line-height: 30px;
            }
        }
        .list-wrap {
            display: flex;
            flex-direction: column;
            margin-top: 14px;
            .card-col{
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
            }
            .item-card{
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                width: 240px;
                padding: 40px 10px 62px 10px;
                transition: all .3s;
                &:hover{
                    .img{
                        transform-origin: center;
                        animation: bounce 1s;
                    }
                    border-radius: 2px;
                    box-shadow: 0 2px 12px 0 rgba(0,0,0,.1);
                    transition: all 1.5s cubic-bezier(0.165, 0.84, 0.44, 1);
                }
                .img{
                    display: inline-block;
                    width: 70px;
                    height: 70px;
                }
                .title{
                    margin-top: 4px;
                    height: 28px;
                    font-size: 20px;
                    font-family: PingFangSC, PingFangSC-Medium;
                    font-weight: 500;
                    text-align: left;
                    color: #000000;
                    line-height: 28px;
                }
                .text {
                    margin-top: 8px;
                    width: 237px;
                    height: 40px;
                    font-size: 14px;
                    font-family: PingFangSC, PingFangSC-Regular;
                    font-weight: 400;
                    text-align: center;
                    color: #5c5c5c;
                    line-height: 20px;
                }
            }
        }
    }

    @keyframes bounce {
        0% {
            transform: scale(0);
        }
        50% {
            transform: scale(1.3);
        }
        100% {
            transform: scale(1);
        }
    }
</style>
