<template>
    <div class="business-process">
        <div class="anchor" id="opertionFlowAnchorId"></div>
        <div class="main-content-wrap">
            <div class="title-wrap">
                <div class="title">{{ $t('rap.home.label.business-title') }}</div>
                <div class="text">{{ $t('rap.home.label.business-text') }}</div>
            </div>
        </div>
        <section v-for="(item, index) in lists"
            :key="index"
            :class="['list-item', { 'even': index%2===0 }]">
            <div v-if="index%2===0"
                class="main-content-wrap content-wrap">
                <div class="title-box" :style="`width: ${ item.titleWidth };`">
                    <div class="title">{{ item.title }}</div>
                    <div class="text">{{ item.text }}</div>
                </div>
                <img class="img" :style="`width: ${ item.imgWidth };height: ${item.imgHeight};`" :src="item.imgUrl" alt="">
            </div>
            <div v-else
                class="main-content-wrap content-wrap">
                <img class="img" :style="`width: ${ item.imgWidth };height: ${item.imgHeight};`" :src="item.imgUrl" alt="">
                <div class="title-box" :style="`width: ${ item.titleWidth };`">
                    <div class="title">{{ item.title }}</div>
                    <div class="text">{{ item.text }}</div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
export default {
    data () {
        return {
            lists: [
                {
                    title: this.$t('rap.home.label.business-title-01'),
                    text: this.$t('rap.home.label.business-text-01'),
                    imgUrl: require('@/assets/home/home-img-02.png'),
                    imgWidth: '248px',
                    imgHeight: '200px',
                    titleWidth: '581px'
                },
                {
                    title: this.$t('rap.home.label.business-title-02'),
                    text: this.$t('rap.home.label.business-text-02'),
                    imgUrl: require('@/assets/home/home-img-03.png'),
                    imgWidth: '283px',
                    imgHeight: '217px',
                    titleWidth: '540px'
                },
                {
                    title: this.$t('rap.home.label.business-title-03'),
                    text: this.$t('rap.home.label.business-text-03'),
                    imgUrl: require('@/assets/home/home-img-04.png'),
                    imgWidth: '283px',
                    imgHeight: '220px',
                    titleWidth: '500px'
                },
                {
                    title: this.$t('rap.home.label.business-title-04'),
                    text: this.$t('rap.home.label.business-text-04'),
                    imgUrl: require('@/assets/home/home-img-05.png'),
                    imgWidth: '312px',
                    imgHeight: '173px',
                    titleWidth: '525px'
                }
            ]
        }
    }
}
</script>

<style lang="scss" scoped>
    .business-process{
        background: white;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: relative;
        .anchor{
            position: absolute;
            top: -$headerHeight;
            z-index: -1;
        }
        .title-wrap {
            margin-top: 56px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            .title{
                height: 65px;
                font-size: 46px;
                font-family: PingFangSC, PingFangSC-Medium;
                font-weight: 500;
                text-align: left;
                color: #000000;
                line-height: 65px;
            }
            .text{
                height: 30px;
                opacity: 0.8;
                font-size: 20px;
                font-family: PingFangSC, PingFangSC-Regular;
                font-weight: 400;
                text-align: left;
                color: #333333;
                line-height: 30px;
            }
        }
        .list-item{
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            height: 290px;
            background: #fafafa;
            &.even{
                background: white;
            }
            transition: .5s cubic-bezier(0.165, 0.84, 0.44, 1);
            &:hover{
                background: #f1f1f1;
                transition: all 1s cubic-bezier(0.165, 0.84, 0.44, 1);
            }
            .content-wrap{
                display: flex;
                flex-direction: row;
                justify-content: space-between;
            }
            .title-box{
                .title{
                    width: 100%;
                    font-size: 36px;
                    font-family: PingFangSC, PingFangSC-Medium;
                    font-weight: 500;
                    color: #000000;
                    line-height: 50px;
                }
                .text{
                    width: 100%;
                    opacity: 0.8;
                    font-size: 24px;
                    font-family: PingFangSC, PingFangSC-Regular;
                    font-weight: 400;
                    text-align: left;
                    color: #040404;
                    line-height: 31px;
                }
            }
            .img{
                display: inline-block;
            }
        }
    }
</style>
