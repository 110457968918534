<template>
    <div class="home-wrap">
        <first></first>
        <our-advantage></our-advantage>
        <business-process></business-process>
        <high-praise></high-praise>
    </div>
</template>

<script>
import First from './components/first'
import OurAdvantage from './components/our-advantage'
import BusinessProcess from './components/business-process'
import HighPraise from './components/high-praise'

export default {
    components: {
        First,
        OurAdvantage,
        BusinessProcess,
        HighPraise
    }
}
</script>

<style lang="scss" scoped>
    .home-wrap{
        display: flex;
        flex-direction: column;
        overflow: hidden;
    }
</style>
